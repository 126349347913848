'use client';
import { FilterItemEntity } from '@entities/desktop/filter-item/ui';
import {
  AGE_FILTERS,
  CHROMA_FILTERS,
  PROPERTIES_FILTERS,
  RARITY_FILTERS,
  RARITY_MM2_FILTERS,
} from '@features/desktop/filter-shop-items/ui/constants';
import { useQueryString } from '@hooks/client';
import { searchParamsHelper } from '@utils/searchParamsHelper';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';

import { Button, Illustration, TagQuantity, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './filter-favorite-items.module.scss';

export const FilterFavoriteItemsFeatureMobile = observer(() => {
  const pathname = usePathname();
  const router = useRouter();

  const { createQueryString, params, mappedParams } = useQueryString();
  const [isOpen, setIsOpen] = useState(false);
  const favorite = useStore()?.favorite;
  const selectedGame = useStore()?.app.selectedGame;

  const handleApplyFilters = () => {
    const { sort: sortParam, ...rest } =
      favorite?.getFiltersAsSearchParams.params;
    const filtersString = `${searchParamsHelper(rest)}`;

    router.push(`${pathname}?&${filtersString}`);
    setIsOpen(prev => !prev);
  };

  const handleResetFilters = () => {
    favorite?.resetFilters();
    router.push(`${pathname}?sort=${params['sort']}`);
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    favorite?.resetFilters();
    favorite?.setInitialFilters(params);
  }, []);

  const { sort, category, ...rest } = params;

  const selectedFilters = [...Array.from(favorite?.getFilters.keys())];

  const filteredOutSort = Array.from(favorite?.getFilters.entries())
    .map(([key, value]) => value.group !== 'sort' && value.filter)
    .filter(Boolean);

  const filtersSize = new Set([
    ...Object.values(rest).flat(),
    ...filteredOutSort,
  ]).size;

  return (
    <div className={styles.container}>
      <Button
        className={styles['trigger-button']}
        onClick={() => setIsOpen(true)}
        width={'full-w'}
        variant={'quaternary'}
        iconLeft={
          <Illustration
            name={'tuning'}
            spriteName={'icons'}
            style={{ color: '#BFBFBF' }}
          />
        }
      />
      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Sheet.Container className={styles.sheetContainer}>
          <Sheet.Header className={styles.header}>
            <Button
              className={styles.backButton}
              onClick={() => setIsOpen(false)}
              width={'content'}
              variant={'quaternary'}
              iconLeft={
                <Illustration name={'arrow-left'} spriteName={'icons'} />
              }
            />
            <div className={styles['header-right-slot']}>
              <Typography
                className={styles['header-title']}
                weight={'semi-bold'}
              >
                Filter
              </Typography>
              <TagQuantity quantity={filtersSize} className={styles.quantity} />
            </div>
          </Sheet.Header>
          <Sheet.Scroller>
            <div className={styles.content}>
              {selectedGame === 'adopt' && (
                <div className={styles.section}>
                  <FilterItemEntity
                    filters={PROPERTIES_FILTERS}
                    label="Properties"
                    isMobile
                    setValue={filter => favorite?.toggleFilter(filter)}
                    selectedValues={selectedFilters}
                  />
                </div>
              )}
              <div className={styles.section}>
                <FilterItemEntity
                  filters={
                    selectedGame === 'adopt'
                      ? RARITY_FILTERS
                      : RARITY_MM2_FILTERS
                  }
                  label="Rarity"
                  isMobile
                  setValue={filter => favorite?.toggleFilter(filter)}
                  selectedValues={selectedFilters}
                />
              </div>
              {selectedGame === 'adopt' && (
                <div className={styles.section}>
                  <FilterItemEntity
                    filters={AGE_FILTERS}
                    label="Age"
                    isMobile
                    setValue={filter => favorite?.toggleFilter(filter)}
                    selectedValues={selectedFilters}
                    onSwitchPress={() => {}}
                  />
                </div>
              )}
            </div>
          </Sheet.Scroller>
          <div className={styles['bottom-buttons']}>
            <Button
              text={'Apply'}
              variant={'primary'}
              disabled={filtersSize === 0}
              className={styles['bottom-button']}
              iconRight={
                <Illustration
                  style={{ color: '#FDFDFD' }}
                  name={'check-square'}
                  spriteName={'icons'}
                />
              }
              onClick={() => handleApplyFilters()}
            />
            <Button
              text={'Reset all filters'}
              variant={'secondary'}
              className={styles['bottom-button']}
              onClick={handleResetFilters}
            />
          </div>
        </Sheet.Container>
      </Sheet>
    </div>
  );
});
