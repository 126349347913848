'use client';
import {
  CardCategoryEntity,
  CardCategoryEntityItem,
} from '@entities/card-category';
import { EmailVerifyFeatureMobile } from '@features/mobile/email-verify';
import { LogoutModalMobile } from '@features/mobile/logout';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { useUser } from '@/shared/api/user/useUser';
import { useStore } from '@/store/context';

import styles from './settings.module.scss';

export const ProfileSettingsWidgetMobile = observer(() => {
  const [logoutOpened, setLogoutOpened] = useState(false);
  const auth = useStore().authorization;
  const { user } = useUser();
  const router = useRouter();

  return (
    <>
      <div className={styles['container-content']}>
        {user?.email && (
          <CardCategoryEntity
            icon={user?.isEmailVerified ? 'shield' : 'danger-triangle'}
            iconColor={user?.isEmailVerified ? '#6941C6' : '#FE9920'}
            variant={'blue'}
            item={'mail'}
            starBackgroundColor={'var(--global-purple100)'}
            title={'E-mail'}
            disabled
            itemSlot={
              <CardCategoryEntityItem as={'banner-card'} variant={'mail'} />
            }
            subtitle={user?.email}
            bottomSlot={<EmailVerifyFeatureMobile />}
          />
        )}
        <div className={styles['container-content-buttons']}>
          <CardCategoryEntity
            icon={'heart'}
            iconColor={'#EB3C3C'}
            variant={'red'}
            itemSlot={
              <CardCategoryEntityItem as={'banner-card'} variant={'heart'} />
            }
            item={'hand'}
            title={'Favorites'}
            onClick={() => router.push('/favorite')}
          />
          <CardCategoryEntity
            icon={'bill-list'}
            variant={'blue'}
            iconColor={'#4891FF'}
            item={'clock'}
            starBackgroundColor={'var(--global-blue100)'}
            itemSlot={
              <CardCategoryEntityItem
                as={'banner-card'}
                variant={'clock-blue'}
              />
            }
            title={'History'}
            onClick={() => router.push('/profile/history/purchases')}
          />
          <CardCategoryEntity
            icon={'gift'}
            variant={'purple'}
            iconColor={'#6941C6'}
            item={'clock'}
            starBackgroundColor={'var(--global-purple100)'}
            itemSlot={
              <CardCategoryEntityItem as={'banner-card'} variant={'gift'} />
            }
            title={'Gift'}
            onClick={() => router.push('/drop')}
          />
          <CardCategoryEntity
            icon={'key-square'}
            variant={'orange'}
            iconColor={'#40BB18'}
            itemSlot={
              <CardCategoryEntityItem
                as={'banner-card'}
                variant={'lock-green'}
              />
            }
            item={'lock'}
            title={'Password'}
            starBackgroundColor={'var(--global-green100)'}
            onClick={() => auth.openReset()}
          />
          <CardCategoryEntity
            icon={'question-square'}
            variant={'orange'}
            iconColor={'#4891FF'}
            itemSlot={
              <CardCategoryEntityItem
                as={'banner-card'}
                variant={'square-question'}
              />
            }
            item={'lock'}
            title={'FAQ'}
            starBackgroundColor={'var(--global-blue100)'}
            onClick={() => router.push('/faq')}
          />
          <CardCategoryEntity
            icon={'exit'}
            variant={'red'}
            iconColor={'#FE9920'}
            itemSlot={
              <CardCategoryEntityItem
                as={'banner-card'}
                variant={'hand-yellow'}
              />
            }
            item={'hand'}
            starBackgroundColor={'var(--global-orange100)'}
            title={'Log out'}
            onClick={() => setLogoutOpened(true)}
          />
        </div>
      </div>
      <LogoutModalMobile
        isOpened={logoutOpened}
        close={() => setLogoutOpened(false)}
      />
    </>
  );
});
