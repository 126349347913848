'use client';
import { CardItemShopEntityMobile } from '@entities/mobile/cards';
import { useProducts } from '@hooks/queries/use-query-products';
import { ContentWidgetItemsBlockProps } from '@widgets/desktop/content/ui/content';
import {
  getHotSalesQueryFilters,
  HOT_SALE_QUERY_KEY,
} from '@widgets/desktop/list-cards-wrappers/hot-sales-items';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label, Skeleton } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './list-cards-wrappers.module.scss';

export const PopularItems = () => {
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getHotSalesQueryFilters,
    uniqueQueryKey: HOT_SALE_QUERY_KEY,
  });

  return (
    <ItemsBlock
      items={items}
      headerSlot={
        <>
          <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
            Popular items
          </Label>
          <SeeAllButton href={'/shop?sort=popular'} variant={'secondary'} />
        </>
      }
    />
  );
};
export const CloseInValueItems = () => {
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getHotSalesQueryFilters,
    uniqueQueryKey: HOT_SALE_QUERY_KEY,
  });

  return (
    <ItemsBlock
      items={items}
      isLoading={isFetching}
      headerSlot={
        <>
          <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
            Items close in value
          </Label>
        </>
      }
    />
  );
};

export const ExclusiveItems = () => {
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getHotSalesQueryFilters,
    uniqueQueryKey: HOT_SALE_QUERY_KEY,
  });

  return (
    <ItemsBlock
      items={items}
      isLoading={isFetching}
      headerSlot={
        <>
          <Label
            labelVariant={'black'}
            icon={'star-circle'}
            variant={'primary'}
          >
            Exclusive Items
          </Label>
          <SeeAllButton href={'/shop?sort=popular'} variant={'secondary'} />
        </>
      }
    />
  );
};
export const HotSalesItems = () => {
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getHotSalesQueryFilters,
    uniqueQueryKey: HOT_SALE_QUERY_KEY,
  });

  return (
    <ItemsBlock
      isLoading={isFetching}
      items={items}
      headerSlot={
        <>
          <Label
            labelVariant={'red'}
            icon={'fire-square-red'}
            variant={'primary'}
          >
            Hot Sales
          </Label>
          <SeeAllButton href={'/shop?sort=popular'} variant={'secondary'} />
        </>
      }
    />
  );
};

export const ItemsBlock = observer(
  ({ headerSlot, items, isLoading }: ContentWidgetItemsBlockProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLargeScreen = useMediaQuery('(min-width: 744px)');
    const isMediumScreen = useMediaQuery('(min-width: 428px)');
    const isSmallScreen = useMediaQuery('(min-width: 320px)');
    const isClient = useIsClient();
    const game = useStore()?.app?.selectedGame;

    const slicedItems = isLargeScreen
      ? items
      : isMediumScreen
        ? items.slice(0, 6)
        : items.slice(0, 6);
    const skeletonsToRender = isLargeScreen ? 16 : isMediumScreen ? 6 : 6;

    return (
      <div className={styles['content-widget__items-block']}>
        {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
        <div className={styles['items']}>
          {!isLoading &&
            slicedItems?.map((item, idx) => {
              return (
                <CardItemShopEntityMobile
                  key={`card-${idx}-${item.realName}-${item?.img}`}
                  item={item}
                  game={game}
                />
              );
            })}

          {isLoading &&
            Array(skeletonsToRender)
              .fill('')
              .map((_, idx) => (
                <Skeleton
                  key={`skeleton-list-cards-${idx}`}
                  className={cn(styles['card-skeleton'])}
                />
              ))}
        </div>
      </div>
    );
  },
);
