'use client';

import { FilterFavoriteItemsFeatureMobile } from '@features/mobile/filters/filter-favorite-items';
import { useQueryString } from '@hooks/client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import { DropListSort } from '@/features/drop-list-sort';
import { Illustration } from '@/shared/ui';

import styles from './filters.module.scss';

export const FavoritesFiltersMobile = () => {
  const inputContainerClasses = cn(styles.main_wrapper);
  const inputWrapperClasses = cn(styles.input_wrapper);
  const inputClasses = cn(styles.input_field);

  const router = useRouter();
  const searchParams = useSearchParams();
  const param = searchParams.get('favoriteSearch');
  const [searchValue, setSearchValue] = useState('');
  const { createQueryString } = useQueryString();

  const setSearchParams = useDebounceCallback(
    useCallback(
      (value: string) =>
        router.push(
          createQueryString({
            pathname: '/favorite',
            name: 'favoriteSearch',
            value: value,
            asMultipleParams: false,
          }),
        ),
      [],
    ),
    500,
  );

  useEffect(() => {
    if (param) {
      setSearchValue(param);
    }
  }, []);

  useEffect(() => {
    setSearchParams(searchValue);
  }, [searchValue]);

  return (
    <div className={styles['filter-section']}>
      <div className={inputContainerClasses}>
        <Illustration
          name={'magnifer'}
          style={{ color: '#6941C6' }}
          spanTagClassName={styles.search_icon}
          spriteName={'icons'}
        />
        <div className={inputWrapperClasses}>
          <input
            type="text"
            placeholder="Name, properties, rarity..."
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            tabIndex={0}
            className={inputClasses}
          />
        </div>
      </div>
      <div className={styles['filter-bottom-slot']}>
        <DropListSort isMobile variant={'favorite'} />
        <FilterFavoriteItemsFeatureMobile />
      </div>
    </div>
  );
};
