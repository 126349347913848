'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { useQueryString } from '@hooks/client';
import { ItemsBlock } from '@widgets/mobile/list-cards-wrappers/list-cards-wrappers';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { useFavorite } from '@/shared/hooks/queries/use-query-favorite';
import { Illustration } from '@/shared/ui';

import styles from './cards.module.scss';

export const FavoritesCardsMobile = observer(() => {
  const { mappedParams } = useQueryString();
  const { items, isLoading } = useFavorite(mappedParams);
  const router = useRouter();

  if (!items.length && !isLoading) {
    return (
      <SidebarFavoritesEntity
        id={'favorite-empty'}
        className={styles.empty}
        topSlot={<SidebarFavoritesEntity.Image variant={'favorite-no-items'} />}
        middleSlot={
          <SidebarFavoritesEntity.Info
            info={{
              title: 'Favorites are empty',
              subtitle: 'Add items using the heart️ button on the item card!',
            }}
          />
        }
        bottomSlot={
          <SidebarFavoritesEntity.ActionButton
            text={'Open categories'}
            onClick={() => router.push('/categories')}
            variant={'primary'}
            iconRight={
              <Illustration
                id={'sidebar-fav-icon'}
                spriteName={'icons'}
                name={'square-arrow-right'}
              />
            }
          />
        }
      />
    );
  }

  return <ItemsBlock items={items} isLoading={isLoading} />;
});
